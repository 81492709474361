import { initApp } from '../../packages/ecomm-vue/dist/thirstie-ecomm-vue.js';
import appConfig from './.env.json';

const apiKey = appConfig.APIKEY;
const mapsKey = appConfig.MAPSKEY;

const thirstieAppConfig = {
  APIKEY: apiKey,
  MAPSKEY: mapsKey,
  primaryColor: '#154D5B',
  secondaryColor: '#ffffff',
  brandLogo: 'https://media.thirstie.cloud/content/2Dt1hdN5CDkHNn7UBCQ5yH,x250f.png',
  routes: {
    checkout: '/checkout.html'
  },
  supportEmail: 'technology@thirstie.com',
  experimental: true,
  plpAddressInput: 'Delivery address to see product availability',
  pdpAddressDisclaimer: 'Address to check retailer availability and price',
  pdpFAQName: 'Delivery Information',
  cartTitle: 'My Bag',
  emptyCartMsg: 'Your bag is empty',
  footerMsg: 'This site is for demonstration purposes only, Tequila Brand and Thirstie are not a licensed beverage alcohol retailer. All sales of alcohol are fulfilled by licensed retailers on the Thirstie Network.',
  faqContent: `
    <h3>Frequently Asked Questions</h3>
    <p><strong>Q: What is this product?</strong></p>
    <p>A: This is a high-quality product designed for customer satisfaction.</p>
    <p><strong>Q: How do I use it?</strong></p>
    <p>A: Simply follow the instructions provided in the manual.</p>
  `
};

globalThis.__VUE_PROD_DEVTOOLS__ = process.env.NODE_ENV === 'development';
globalThis.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = process.env.NODE_ENV === 'development';
globalThis.__VUE_OPTIONS_API__ = false;
initApp(thirstieAppConfig);
